import React from "react";
import { Link } from "react-router-dom";
import Home from "./theme/Home";

export default function HomeLogin() {
  return (
    <Home
      form={
        <div className="home-links">
          <Link className="btn" to="/login-master">
            Je suis chef d'équipe
          </Link>
          <Link className="btn" to="/login">
            Je ne suis pas chef d'équipe
          </Link>
        </div>
      }
    />
  );
}
