import { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useBeforeunload } from "react-beforeunload";
import sf from "seconds-formater";
import ReactPlayer from "react-player/youtube";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  CloseIcon,
  CheckIcon,
} from "@chakra-ui/icons";
import { Howl } from "howler";
import { createStore, createHook, defaults } from "react-sweet-state";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import throttle from "lodash.throttle";
import {
  useToast,
  Button as ChakraButton,
  RadioGroup,
  Radio,
  Stack,
  Select,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
} from "@chakra-ui/react";
import slugify from "./slugify";
import GameTheme from "./theme/Game";
import { db } from "./config";

const syncToBase = throttle((state) => {
  db.doc(state.teamPath).set(state, {
    merge: true,
  });
}, 500);

const firestore = (storeState) => (next) => (arg) => {
  const result = next(arg);

  if (!storeState.getState().isSpectator && storeState.getState().teamPath) {
    const { scoresById, ...nextState } = storeState.getState();
    syncToBase(nextState);
  }

  return result;
};

defaults.middlewares.add(firestore);

const ding = new Howl({
  src: ["/sounds/ding.mp3"],
});

const tictac = new Howl({
  src: ["/sounds/tictac.mp3"],
  loop: true,
});

export const questions = {
  1: {
    question: "Que met Miss Frogenchtop sur ses pancakes ?",
    answer: ["sirop derable", "sirop erable"],
    points: 15,
  },
  2: {
    question: "Qu'a-t-elle autour du cou ?",
    answer: ["collier de perle", "collier"],
    points: 20,
  },
  3: {
    question: "De quelle star est-il fan ?",
    answer: ["lady gaga"],
    points: 15,
  },
  4: {
    question: "D'où vient la cuillère Fulbrok ?",
    answer: ["ikea"],
    points: 20,
  },
  5: {
    question: "(DOC) Comment est mort précisément Tokushuryu ?",
    answer: ["yakitori"],
    points: 15,
  },
  6: {
    question: "(DOC) A quel âge Krazen Krätz est devenu fan de pesto ?",
    answer: ["7 ans et demi", "sept ans et demi", "7,5"],
    points: 15,
  },
  7: {
    question: "Qu'est-ce qui est le plus précieux pour le journaliste ?",
    options: ["L'amulette", "Son carnet", "Sa liberté"],
    answer: ["Son carnet"],
    points: 20,
  },
  8: {
    question: "A-t-il déjà voyagé dans le temps ?",
    answer: ["oui"],
    points: 20,
  },
  9: {
    question:
      "Quel est l’animal imaginaire invoqué par le voleur, ou la voleuse, pour distraire l’alchimiste ?",
    answer: ["brebis vampire"],
    points: 20,
  },
  10: {
    question: "De quelle couleur sont ses cheveux ?",
    options: ["Lune argentée", "Gris", "Blanc", "Blond"],
    answer: ["Gris", "Lune argentée"],
    points: 20,
  },
  11: {
    question: "Comment s'appelle le matelot ?",
    answer: ["juan", "jean"],
    points: 20,
  },
  12: {
    question: "Que mange-t-il depuis 6 mois ?",
    options: [
      "Des fleurs et des courgettes",
      "Des frites et des burgers supplément bacon",
      "Des graines de courges et des bananes",
      "Du manioc et des bananes",
    ],
    answer: ["Du manioc et des bananes"],
    points: 20,
  },
  13: {
    question: "En quelle année se déroule la scène ?",
    answer: ["- 11557", "-11557"],
    points: 25,
  },
  14: {
    question:
      "Qu'est-ce que le barbu a dans ses oreilles au début de la scène ?",
    answer: ["rien"],
    points: 15,
  },
  15: {
    question:
      "(DOC) Où, Rich'La Bouche et sa femme, se rendaient-ils avant d'avoir leur terrible accident ?",
    options: ["à Aqualand", "à Aquaboulvard", "à Aquasplash", "à Aquabonnager"],
    answer: ["à Aquasplash"],
    points: 25,
  },
  16: {
    question: "(DOC) Comment s'appelle le principal rival de Krazen Krätz ?",
    answer: ["abdul mascarpaccio"],
    points: 20,
  },
  17: {
    question: "Quel apôtre est accusé par Juda de traitrise ?",
    options: ["Saint Simon", "Saint d'esprit", "Saint Pierre", "Saint Marc"],
    answer: ["Saint Pierre"],
    points: 20,
  },
  18: {
    question: "Que risque les gens qui volent des pommes à cette époque ?",
    answer: ["lapidation"],
    points: 20,
  },
  19: {
    question:
      "Quelle est le nom de jeune fille de l'épouse de Lucien Frogenchtop ?",
    options: [
      "Mme Rêvedatomiseur",
      "Mme de Philipons",
      "Mme Fripon",
      "Mme Frogenchtop",
    ],
    answer: ["Mme de Philipons"],
    points: 20,
  },
  20: {
    question: "Selon le prêtre, quel apôtre aurait touché l'amulette ?",
    answer: ["thomas"],
    points: 20,
  },
  21: {
    question: "De quelle couleur est son maillot ?",
    options: [
      "Noir",
      "Taupe tirant sur camel et teinte souris",
      "Gris antracite",
      "Cyan",
    ],
    answer: ["Noir"],
    points: 20,
  },
  22: {
    question: "De quel film est-il fan ?",
    answer: ["grand bleu"],
    points: 20,
  },
  23: {
    question: "Quel est le nom de leur ultime spécialité ?",
    options: [
      "Le nunchaku de geisha",
      "Les fontaines jumelles",
      "Les chutes du viagragra",
      "La cascade siamoise",
    ],
    answer: ["La cascade siamoise"],
    points: 20,
  },
  24: {
    question:
      "À quel jeu ont-elles joué pour savoir qui s'occuperait du « nouveau client » ?",
    answer: [
      "shi fu mi",
      "shi fu mi",
      "shifume",
      "chi fou mi",
      "chi fu mi",
      "shifumi",
      "chifumi",
    ],
    points: 20,
  },
  25: {
    question: "Qui a volé la fabuleuse amulette de Miss Frogenchtop ?",
    answer: ["Krazen Krätz"],
    options: ["Krazen Krätz", "Rich Labouche", "Sophie Duval"],
    points: 50,
  },
  26: {
    question: "Où se cache-t-il ? T'elle ?",
    answer: ["Mésopotamie"],
    options: [
      "Pérou",
      "Garage Mythos",
      "Agence Prizoners",
      "Ile Maurice",
      "Musée Léopold",
      "Mésopotamie",
      "Ikea",
    ],
    points: 50,
  },
  27: {
    question: "Quel est le mobile du vol ?",
    answer: ["Pour obtenir les meilleurs pignons de pin de l’histoire"],
    options: [
      "Pour pouvoir faire son contrôle technique",
      "Pour acheter son couteau Fulbrok",
      "Pour accéder à la plus grosse réserve d’or du monde",
      "Pour voir Blanche Neige quand il est sorti au cinéma",
      "Pour obtenir les meilleurs pignons de pin de l’histoire",
    ],
    points: 50,
  },
  28: {
    question:
      "(DOC) Avez vous su trouver de quelle matière précise était composée l'amulette ?",
    answer: ["ebene"],
    points: 30,
  },
  29: {
    question:
      "(DOC) Où doit se rendre notre voleur ou voleuse pour pouvoir reconstituer l'amulette ?",
    answer: ["ile maurice"],
    points: 30,
  },
  30: {
    question: "(DOC) Alors notre voleur est un voleur ? ou une voleuse ?",
    answer: ["Un voleur"],
    options: ["Un voleur", "Une voleuse"],
    points: 30,
  },
  31: {
    question: "(DOC) Ecrivez nous ici votre transcription !",
    answer: ["sauvages"],
    points: 30,
  },
  32: {
    question: "(DOC) Quel est le mot de passe du téléphone de Sophie ?",
    answer: ["ouragan"],
    points: 20,
  },
  33: {
    question: "(Doc) Quel est le passe-temps favori de Sophie Duval ?",
    answer: ["Cambrioler des musées"],
    options: [
      "Mettre en place des projets d’évasion de grands prisonniers",
      "Cambrioler des musées",
      "Braquer des Banques",
      "Faire chanter les maitres chanteurs",
    ],
    points: 20,
  },
  34: {
    question: "(Doc) Où se trouve Krazen Krätz ?",
    answer: ["En Mésopotamie"],
    options: [
      "Au Brésil",
      "Au Mexique",
      "En Mésopotamie",
      "En Chine",
      "En Inde",
    ],
    points: 20,
  },
  35: {
    question:
      "(DOC) Que nous dit la photo retrouvée dans le portable de Sophie Duval, quelle est sa passion ?",
    answer: ["Les toiles de Grands Maitres"],
    options: [
      "Les reliques religieuses",
      "Les arts premiers",
      "Les toiles de Grands Maitres",
    ],
    points: 20,
  },
  36: {
    question: "(DOC) Dans quel pays se trouve Rich Labouche ?",
    answer: ["perou"],
    points: 20,
  },
};

export const documents = {
  1: { url: "/documents/1.jpg", title: "Amulette reconstituée" },
  2: { url: "/documents/2.jpg", title: "Composition de l'amulette" },
  3: { url: "/documents/3.jpg", title: "Liste appel Krazen Kratz" },
  4: { url: "/documents/4.jpg", title: "Liste appel Rich la bouche" },
  5: { url: "/documents/5.jpg", title: "Liste appel Sophie Duval" },
  6: { url: "/documents/6.jpg", title: "Chronologie" },
  71: { url: "/documents/71.jpg", title: "Fiche info Krazen Krätz" },
  72: { url: "/documents/72.jpg", title: "Fiche info Rich la bouche" },
  73: { url: "/documents/73.jpg", title: "Fiche info Sophie Duval" },
  81: { url: "/documents/81.jpg", title: "Témoignage Garagiste" },
  82: { url: "/documents/82.mp3", title: "Collectionneur Phillidore Khan" },
  9: { url: "/documents/9.jpg", title: "Journaliste Puri", temp: true },
  10: { url: "/documents/10.jpg", title: "Alchimiste Tessalaria", temp: true },
  11: { url: "/documents/11.jpg", title: "Trois mots matelos", temp: true },
  12: { url: "/documents/12.mp3", title: "Paleobotaniste" },
  13: {
    url: "/documents/13.jpg",
    title: "Journal le parisien Pestotiste",
    temp: true,
  },
  14: { url: "/documents/14.jpg", title: "Rapport Labo Sexe", temp: true },
  15: { url: "/documents/15.jpg", title: "Vision 3000" },
  16: { url: "/documents/16.jpg", title: "Témoignage Louis XIV" },
  17: { url: "/documents/17.jpg", title: "Bonhommes dansants" },
  18: { url: "/documents/18.jpg", title: "Message de Napoléon" },
  19: {
    url: "/documents/19.mp3",
    title: "Message Audio trouvé dans le téléphone de Sophie Duval",
  },
  20: {
    url: "/documents/20.jpg",
    title: "Photo étrange trouvée dans téléphone Sophie Duval",
  },
  21: {
    url: "/documents/21.jpg",
    title: "Carte mysterieuse retrouvée chez Krazen Krätz",
  },
  22: {
    url: "/documents/22.mp3",
    title: "Rapport Audio Perquisition Richelieu",
  },
  23: {
    url: "/documents/23.jpg",
    title: "Les Suspects",
  },
};

const videos = {
  1: { title: "Vidéo intro", url: "https://youtu.be/0_175fJjA-0" },
  2: { title: "Vidéo suspects", url: "https://youtu.be/46AOgMXtOwQ" },
  3: { title: "Miss Frogenchtop", url: "https://youtu.be/0pKSG6TIzw0" },
  4: { title: "Le Gardien", url: "https://youtu.be/LFQE7ECaazA" },
  5: { title: "Le Journaliste", url: "https://youtu.be/CVBfCrpzaMU" },
  6: { title: "L’alchimiste", url: "https://youtu.be/ukKNEysn2XE" },
  7: { title: "Le Matelot", url: "https://youtu.be/R1nKgR32Vls" },
  8: { title: "Hommes des cavernes", url: "https://youtu.be/EF67yuG1aUw" },
  9: { title: "Judas et St Thomas", url: "https://youtu.be/a7CpMa85fUU" },
  10: { title: "Le Prêtre", url: "https://youtu.be/9g_07a9P15g" },
  11: { title: "L’apneiste", url: "https://youtu.be/1ok2yvNlmdA" },
  12: { title: "Les Filles de Joie", url: "https://youtu.be/xlxPYw4lK2g" },
  13: { title: "Conclusion Win", url: "https://youtu.be/xyiYMZE6Sdo" },
  14: { title: "Conclusion Lose 2", url: "https://youtu.be/rtJA41tHu0Q" },
  15: { title: "Conclusion Lose 3", url: "https://youtu.be/zILGzl_kK4Q" },
};

function isRight({ answer, options, value }) {
  return !options
    ? answer.some(
        (rightAnswer) =>
          slugify(value).includes(slugify(rightAnswer)) ||
          value.includes(rightAnswer)
      )
    : answer.includes(value);
}

function getScore(id, value, elapsed) {
  let { points = 0, answer, options } = questions[id];

  const isRightAnswer = isRight({ answer, options, value });

  if (id === 2 && isRightAnswer && !slugify(value).includes("perle")) {
    points = 10;
  }

  if (isRightAnswer) {
    if (elapsed < 20 * 1000) {
      return points + 8;
    } else if (elapsed < 20 * 1000) {
      return points + 8;
    } else if (elapsed < 45 * 1000) {
      return points + 6;
    } else if (elapsed < 60 * 1000) {
      return points + 4;
    } else if (elapsed < 120 * 1000) {
      return points + 2;
    }

    return points;
  }

  return 0;
}

const emptyObj = {};

const initialState = {
  isSpectator: false,
  questionIndex: 0,
  loaded: false,
  screen: "",
  answerIds: [],
  answersById: {},
  scoresById: {},
  score: 0,
  lastScore: emptyObj,
  elapsedById: {},
  timer: "",
  availableDocuments: [],
  speed: 1,
  finished: false,
};

const Store = createStore({
  initialState,
  actions: {
    initState: (initialState) => ({ setState }) =>
      setState({ ...initialState, loaded: true }),
    setSpectatorState: (nextState) => ({ setState, getState }) => {
      let diff = {};

      for (const key in nextState) {
        if (
          JSON.stringify(nextState[key]) !== JSON.stringify(getState()[key])
        ) {
          diff[key] = nextState[key];
        }
      }

      setState({ ...diff, loaded: true });
    },
    setScreen: (screen) => ({ setState, getState }) => {
      tictac.stop();

      const docsToRemove = getState()
        .availableDocuments.map((id) => ({ id, ...documents[id] }))
        .filter((it) => it.temp)
        .map((it) => it.id);

      setState({
        questionIndex: 0,
        timer: "",
        screen,
        availableDocuments: getState().availableDocuments.filter(
          (id) => !docsToRemove.includes(id)
        ),
      });
    },
    answerQuestion: (id, value) => ({ setState, getState }) => {
      const questionScore = getScore(id, value, getState().elapsedById[id]);
      const scoresById = { ...getState().scoresById, [id]: questionScore };

      const score = Object.values(scoresById).reduce(
        (acc, score) => acc + score,
        0
      );

      const nextState = {
        answerIds: [...getState().answerIds, id],
        answersById: { ...getState().answersById, [id]: value },
        scoresById,
        score,
        lastScore: { id, value, score: questionScore },
      };

      db.doc(getState().teamPath).set(
        {
          scoresById,
        },
        {
          merge: true,
        }
      );

      setState({
        ...nextState,
      });
    },
    setScore: (scoresById = {}) => ({ setState }) => {
      const score = Object.values(scoresById).reduce(
        (acc, score) => acc + score,
        0
      );

      setState({
        scoresById,
        score,
        lastScore: emptyObj,
      });
    },
    setQuestionElapsed: (id, value) => ({ setState, getState }) => {
      setState({
        elapsedById: {
          ...getState().elapsedById,
          [id]: value,
        },
      });
    },
    addDocuments: (ids) => ({ setState, getState }) => {
      setState({
        notifyDocs: ids.join(","),
        availableDocuments: [
          ...new Set([...getState().availableDocuments, ...ids]),
        ],
      });
    },
    setTimer: (timer) => ({ setState, getState }) => {
      if (!getState().isSpectator) {
        setState({
          timer,
        });
      }
    },
    setQuestionIndex: (index) => ({ setState }) => {
      setState({
        questionIndex: index,
      });
    },
    setSpeed: (speed) => ({ setState }) => {
      setState({
        speed,
      });
    },
    clearNotifyDocs: () => ({ setState }) => {
      setState({
        notifyDocs: "",
      });
    },
    clearLastScore: () => ({ setState }) => {
      setState({
        lastScore: {},
      });
    },
    setFinished: () => ({ setState }) => {
      setState({
        finished: true,
      });
    },
  },
  name: "game",
});

const useGame = createHook(Store);

function Video({ id, onEnded }) {
  const { url } = videos[id];

  return (
    <div className="video">
      <ReactPlayer
        config={{
          youtube: {
            playerVars: {
              modestbranding: 1,
            },
          },
        }}
        width={1000}
        height={605}
        playing
        url={url}
        onEnded={onEnded}
      />
    </div>
  );
}

function Button(props) {
  return <button className="btn" {...props} />;
}

function Document({ id, readDocuments, readDocument }) {
  const [isOpen, setIsOpen] = useState(false);
  const { title, url } = documents[id];
  const isRead = readDocuments.includes(id);

  useEffect(() => {
    if (isOpen) {
      readDocument(id);
    }
  }, [id, isOpen]);

  return (
    <>
      <Modal size="xl" isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          {url.endsWith(".mp3") ? (
            <audio controls src={url} />
          ) : (
            <Image src={url} alt={title} />
          )}
        </ModalContent>
      </Modal>
      <div
        className={
          isRead
            ? "animate__animated animate__bounceIn document read"
            : "animate__animated animate__bounceIn document"
        }
        onClick={() => setIsOpen(true)}
      >
        <div className="document-title">{title}</div>
      </div>
    </>
  );
}

function Documents() {
  const [readDocuments, setReadDocuments] = useState([]);
  const [{ availableDocuments }] = useGame();

  function readDocument(id) {
    setReadDocuments((prev) => [...prev, id]);
  }

  return (
    <div className="documents">
      {[...availableDocuments].reverse().map((id) => (
        <Document
          key={id}
          id={id}
          readDocuments={readDocuments}
          readDocument={readDocument}
        />
      ))}
    </div>
  );
}

function Question({ id, onSubmit }) {
  const [elapsed, setElapsed] = useState(0);
  const { question, options } = questions[id];
  const [{ answersById }, { answerQuestion, setQuestionElapsed }] = useGame();
  const answer = answersById[id];

  useEffect(() => {
    const to = setInterval(() => {
      setElapsed((prevElapsed) => {
        return prevElapsed + 1000;
      });
    }, 1000);

    return () => {
      clearInterval(to);
    };
  }, []);

  useEffect(() => {
    setQuestionElapsed(id, elapsed);
  }, [elapsed, setQuestionElapsed, id]);

  return (
    <div className={`question ${answer ? "question-complete" : ""}`}>
      <div className="question-title">{question}</div>
      <Formik
        isInitialValid={false}
        initialValues={{ answer }}
        validationSchema={Yup.object().shape({
          answer: Yup.string().required("Answer is required"),
        })}
        onSubmit={(value) => {
          answerQuestion(id, value.answer, elapsed);
          onSubmit();
        }}
      >
        {({ setFieldValue, isValid }) => (
          <Form className="question-form">
            {question && (
              <>
                {options ? (
                  <Field name="answer">
                    {({ field }) => (
                      <RadioGroup
                        name="anwser"
                        value={field.value}
                        onChange={(value) => {
                          setFieldValue("answer", value);
                        }}
                      >
                        <Stack>
                          {options.map((option, i) => (
                            <Radio
                              key={i}
                              name="anwser"
                              disabled={!!answer}
                              value={option}
                              isChecked={field.value === option}
                            >
                              {option}
                            </Radio>
                          ))}
                        </Stack>
                      </RadioGroup>
                    )}
                  </Field>
                ) : (
                  <Field
                    disabled={!!answer}
                    name="answer"
                    className="field-text"
                    type="text"
                    autoComplete="off"
                    placeholder="Votre réponse"
                  />
                )}
                <div className="submit-wrapper">
                  <button
                    type="submit"
                    className="btn"
                    disabled={!!answer}
                    style={{ display: isValid ? "block" : "none" }}
                  >
                    Enregistrer
                  </button>
                </div>
              </>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
}

function QuestionsGroup({
  duration,
  questions = [],
  documents = [],
  nextScreen,
}) {
  const [
    { questionIndex, answersById, scoresById },
    { setScreen, addDocuments, setQuestionIndex },
  ] = useGame();

  const questionId = questions[questionIndex];

  useEffect(() => {
    if (documents.length) {
      addDocuments(documents);
    }
    //eslint-disable-next-line
  }, []);

  function questionStatus(id) {
    if (!answersById[id]) {
      return null;
    }
    if (scoresById[id]) {
      return <CheckIcon size="16px" color="green.500" />;
    }

    return <CloseIcon size="16px" color="red.500" />;
  }

  return (
    <>
      <Timer duration={duration} onEnded={() => setScreen(nextScreen)} />
      {questions.length > 0 && (
        <>
          <Question
            key={questionId}
            id={questionId}
            onSubmit={() => {
              if (questionIndex <= questions.length - 2) {
                setQuestionIndex(questionIndex + 1);
              }
            }}
          />
          {questionIndex > 0 && (
            <div
              className="btn-nav-left"
              onClick={() => setQuestionIndex(questionIndex - 1)}
            >
              <ChevronLeftIcon />
            </div>
          )}
          {questionIndex < questions.length - 1 && (
            <div
              className="btn-nav-right"
              onClick={() => setQuestionIndex(questionIndex + 1)}
            >
              <ChevronRightIcon />
            </div>
          )}
          <div className="btn-navs">
            {questions.map((id, i) => (
              <div
                key={i}
                className={
                  questionIndex === i
                    ? "btn-navs-item current"
                    : "btn-navs-item"
                }
                onClick={() => setQuestionIndex(i)}
              >
                <div className="btn-navs-num">{i}</div>
                <div>{questionStatus(id)}</div>
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
}

function QuestionFinal({ duration, questions = [], documents = [] }) {
  const [
    { answersById, scoresById, questionIndex = 0 },
    { setScreen, addDocuments, setQuestionIndex, setFinished },
  ] = useGame();

  const questionId = questions[questionIndex];

  useEffect(() => {
    if (documents.length) {
      addDocuments(documents);
    }
    //eslint-disable-next-line
  }, []);

  function questionStatus(id) {
    if (!answersById[id]) {
      return null;
    }
    if (scoresById[id]) {
      return <CheckIcon size="16px" color="green.500" />;
    }

    return <CloseIcon size="16px" color="red.500" />;
  }

  function lastQuestionTransition() {
    if (answersById[25] === "Krazen Krätz") {
      setScreen("videoWin");
    } else if (answersById[25] === "Rich Labouche") {
      setScreen("videoLoose2");
    } else if (answersById[25] === "Sophie Duval") {
      setScreen("videoLoose3");
    } else {
      setScreen("videoLoose3");
    }
    setFinished();
  }

  return (
    <>
      <Timer duration={duration} onEnded={lastQuestionTransition} />
      {questions.length > 0 && (
        <>
          <Question
            key={questionId}
            id={questionId}
            onSubmit={() => {
              if (questionIndex <= questions.length - 2) {
                setQuestionIndex(questionIndex + 1);
              }
            }}
          />
          {questionIndex > 0 && (
            <div
              className="btn-nav-left"
              onClick={() => setQuestionIndex(questionIndex - 1)}
            >
              <ChevronLeftIcon />
            </div>
          )}
          {questionIndex < questions.length - 1 && (
            <div
              className="btn-nav-right"
              onClick={() => setQuestionIndex(questionIndex + 1)}
            >
              <ChevronRightIcon />
            </div>
          )}
          <div className="btn-navs">
            {questions.map((id, i) => (
              <div
                key={i}
                className={
                  questionIndex === i
                    ? "btn-navs-item current"
                    : "btn-navs-item"
                }
                onClick={() => setQuestionIndex(i)}
              >
                <div className="btn-navs-num">{i}</div>
                <div>{questionStatus(id)}</div>
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
}

function GameEnded() {
  const [{ score }] = useGame();

  return (
    <div className="game-ended">
      <div>
        <h2>Le Jeu est terminé !</h2>
        <p>MERCI à vous pour votre excellent travail</p>
        <h3>Votre score est de {score} points !</h3>
        <p>
          Bon si vous êtes en dessous de 200 points, c’est pas très brillant.
          <br />
          Entre 201 et 400 points c’est franchement cool !
          <br />
          Entre 401 et 500 points, c’est carrément génial !
          <br />
          Au-delà de 501 points, vous êtes un super Syan !
        </p>
        <p>
          Si un animateur vous accompagne pour votre session, il vous rappellera
          d’ici quelques secondes sur la salle principale pour un debrief !
        </p>
      </div>
    </div>
  );
}

function Timer({ duration, onEnded, stressSound = true }) {
  const [{ speed, isSpectator }, { setTimer }] = useGame();
  const [remaining, setRemaining] = useState(duration);

  useEffect(() => {
    if (!isSpectator) {
      tictac.stop();
      ding.play();

      const to = setInterval(() => {
        setRemaining((prevRemaining) => {
          const nextRemainning = Math.max(0, prevRemaining - 1000);

          if (stressSound && nextRemainning < 30 * 1000 && !tictac.playing()) {
            tictac.play();
          }
          return Math.max(0, prevRemaining - 1000);
        });
      }, 1000 / speed);

      return () => {
        tictac.stop();
        clearInterval(to);
      };
    }
  }, [speed, isSpectator]);

  useEffect(() => {
    if (!remaining && onEnded && !isSpectator) {
      onEnded();
    }
  }, [remaining, onEnded, isSpectator]);

  useEffect(() => {
    if (!isSpectator) {
      setTimer(sf.convert(remaining / 1000).format("MM:SS"));
    }
  }, [remaining, setTimer, isSpectator]);

  return null;
}

const Screens = {
  intro$1: ({ setScreen }) => (
    <Video id={1} onEnded={() => setScreen("intro$2")} />
  ),
  intro$2: () => (
    <QuestionsGroup
      duration={7 * 60 * 1000}
      nextScreen="intro$4"
      questions={[15, 5, 6]}
      documents={[23, 1, 71, 72, 73]}
    />
  ),
  intro$3: ({ setScreen }) => (
    <Video id={2} onEnded={() => setScreen("intro$4")} />
  ),
  intro$4: ({ setScreen }) => (
    <div className="choice">
      <Timer
        stressSound={false}
        duration={30 * 1000}
        onEnded={() => setScreen("frogenchtop$1")}
      />
      <p>
        Vous avez maintenant la possibilité d’entendre divers personnages liés à
        cette malencontreuse aventure, à vous de décider quel personnage sera le
        plus important dans cette enquête ! Vous ne pourrez plus revenir sur
        votre décision: faites le bon choix !
      </p>
      <div className="choice-wrapper">
        <Button onClick={() => setScreen("frogenchtop$1")}>
          Miss FrogenChtop
        </Button>
        <Button onClick={() => setScreen("gardien$1")}>Le Gardien</Button>
      </div>
    </div>
  ),
  frogenchtop$1: ({ setScreen }) => (
    <Video id={3} onEnded={() => setScreen("frogenchtop$2")} />
  ),
  frogenchtop$2: () => (
    <QuestionsGroup
      duration={10 * 60 * 1000}
      nextScreen="frogenchtop$3"
      questions={[1, 2, 28, 32]}
      documents={[2, 6, 18]}
    />
  ),
  frogenchtop$3: ({ setScreen }) => (
    <div className="choice">
      <Timer
        stressSound={false}
        duration={30 * 1000}
        onEnded={() => setScreen("alchimiste$1")}
      />
      <p>
        Et qui allez vous écouter maintenant ? L’alchimiste ? Le journaliste ?
        Ne vous trompez pas !
      </p>
      <div className="choice-wrapper">
        <Button onClick={() => setScreen("alchimiste$1")}>L'alchimiste</Button>
        <Button onClick={() => setScreen("journaliste$1")}>
          Le journaliste
        </Button>
      </div>
    </div>
  ),
  alchimiste$1: ({ setScreen }) => (
    <Video id={6} onEnded={() => setScreen("alchimiste$2")} />
  ),
  alchimiste$2: () => (
    <QuestionsGroup
      duration={14 * 60 * 1000}
      nextScreen="alchimiste$3"
      questions={[9, 10, 29, 33, 34]}
      documents={[81, 10, 19, 21]}
    />
  ),
  alchimiste$3: ({ setScreen }) => (
    <div className="choice">
      <Timer
        stressSound={false}
        duration={30 * 1000}
        onEnded={() => setScreen("prehistoire$1")}
      />
      <p>
        Quel chemin parcouru ! Mais ce n’est pas fini… Jusqu’à présent, vous
        avez eu le choix, mais ce coup ci, non ! En effet, nous avons capté une
        interférence majeure dans le continuum espace temps…
      </p>
    </div>
  ),
  journaliste$1: ({ setScreen }) => (
    <Video id={5} onEnded={() => setScreen("journaliste$2")} />
  ),
  journaliste$2: () => (
    <QuestionsGroup
      duration={14 * 60 * 1000}
      nextScreen="journaliste$3"
      questions={[7, 8, 29, 33, 34]}
      documents={[81, 10, 19, 21]}
    />
  ),
  journaliste$3: ({ setScreen }) => (
    <div className="choice">
      <Timer
        stressSound={false}
        duration={30 * 1000}
        onEnded={() => setScreen("prehistoire$1")}
      />
      <p>
        Quel chemin parcouru ! Mais ce n’est pas fini… Jusqu’à présent, vous
        avez eu le choix, mais ce coup ci, non ! En effet, nous avons capté une
        interférence majeure dans le continuum espace temps…
      </p>
    </div>
  ),
  prehistoire$1: ({ setScreen }) => (
    <Video id={8} onEnded={() => setScreen("prehistoire$2")} />
  ),
  prehistoire$2: () => (
    <QuestionsGroup
      duration={8 * 60 * 1000}
      nextScreen="prehistoire$3"
      questions={[13, 14, 16, 30, 35]}
      documents={[12, 13, 14, 20]}
    />
  ),
  prehistoire$3: ({ setScreen }) => (
    <div className="choice">
      <Timer
        stressSound={false}
        duration={30 * 1000}
        onEnded={() => setScreen("judas$1")}
      />
      <p>
        Pour la dernière fois, vous allez avoir un choix à faire… Mais
        choisissez bien car quelque part, se cache une information cruciale !
      </p>
      <div className="choice-wrapper">
        <Button onClick={() => setScreen("judas$1")}>
          Judas et Saint Thomas
        </Button>
        <Button onClick={() => setScreen("pretre$1")}>Le prêtre</Button>
        <Button onClick={() => setScreen("apneiste$1")}>L'apnéiste</Button>
      </div>
    </div>
  ),
  judas$1: ({ setScreen }) => (
    <Video id={9} onEnded={() => setScreen("judas$2")} />
  ),
  judas$2: () => (
    <QuestionsGroup
      duration={8 * 60 * 1000}
      nextScreen="final$1"
      questions={[17, 18, 31, 36]}
      documents={[15, 16, 17, 19, 22]}
    />
  ),
  pretre$1: ({ setScreen }) => (
    <Video id={10} onEnded={() => setScreen("pretre$2")} />
  ),
  pretre$2: () => (
    <QuestionsGroup
      duration={8 * 60 * 1000}
      nextScreen="final$1"
      questions={[19, 20, 31, 36]}
      documents={[15, 16, 17, 19, 22]}
    />
  ),
  apneiste$1: ({ setScreen }) => (
    <Video id={11} onEnded={() => setScreen("apneiste$2")} />
  ),
  apneiste$2: () => (
    <QuestionsGroup
      duration={10 * 60 * 1000}
      nextScreen="final$1"
      questions={[21, 22, 31, 36]}
      documents={[15, 16, 17, 22, 19]}
    />
  ),
  gardien$1: ({ setScreen }) => (
    <Video id={4} onEnded={() => setScreen("gardien$2")} />
  ),
  gardien$2: () => (
    <QuestionsGroup
      duration={10 * 60 * 1000}
      nextScreen="gardien$3"
      questions={[3, 4, 28, 32]}
      documents={[2, 6, 71, 72, 73, 18]}
    />
  ),
  gardien$3: ({ setScreen }) => (
    <div className="choice">
      <Timer
        stressSound={false}
        duration={30 * 1000}
        onEnded={() => setScreen("alchimiste$1")}
      />
      <p>
        Et qui allez vous écouter maintenant ? L’Alchimiste ? Le matelot ? Ne
        vous trompez pas !
      </p>
      <div className="choice-wrapper">
        <Button onClick={() => setScreen("alchimiste$1")}>L'alchimiste</Button>
        <Button onClick={() => setScreen("matelot$1")}>Le matelot</Button>
      </div>
    </div>
  ),
  matelot$1: ({ setScreen }) => (
    <Video id={7} onEnded={() => setScreen("matelot$2")} />
  ),
  matelot$2: () => (
    <QuestionsGroup
      duration={14 * 60 * 1000}
      nextScreen="matelot$3"
      questions={[11, 12, 29, 33, 34]}
      documents={[81, 10, 19, 21]}
    />
  ),
  matelot$3: ({ setScreen }) => (
    <div className="choice">
      <Timer
        stressSound={false}
        duration={30 * 1000}
        onEnded={() => setScreen("prehistoireAlt$1")}
      />
      <p>
        Quel chemin parcouru ! Mais ce n’est pas fini… Jusqu’à présent, vous
        avez eu le choix, mais ce coup ci, non ! En effet, nous avons capté une
        interférence majeure dans le continuum espace temps…
      </p>
    </div>
  ),
  prehistoireAlt$1: ({ setScreen }) => (
    <Video id={8} onEnded={() => setScreen("prehistoireAlt$2")} />
  ),
  prehistoireAlt$2: () => (
    <QuestionsGroup
      duration={8 * 60 * 1000}
      nextScreen="prehistoireAlt$3"
      questions={[13, 14, 16, 30, 35]}
      documents={[12, 13, 14, 20]}
    />
  ),
  prehistoireAlt$3: ({ setScreen }) => (
    <div className="choice">
      <Timer
        stressSound={false}
        duration={30 * 1000}
        onEnded={() => setScreen("judas$1")}
      />
      <p>
        Pour la dernière fois, vous allez avoir un choix à faire… Mais
        choisissez bien car quelque part, se cache une information cruciale !
      </p>
      <div className="choice-wrapper">
        <Button onClick={() => setScreen("filles$1")}>
          Les filles de joie
        </Button>
        <Button onClick={() => setScreen("pretre$1")}>Le prêtre</Button>
        <Button onClick={() => setScreen("apneiste$1")}>L'apnéiste</Button>
      </div>
    </div>
  ),
  filles$1: ({ setScreen }) => (
    <Video id={12} onEnded={() => setScreen("filles$2")} />
  ),
  filles$2: () => (
    <QuestionsGroup
      duration={8 * 60 * 1000}
      nextScreen="final$1"
      questions={[23, 24, 31, 36]}
      documents={[15, 16, 17, 19, 22]}
    />
  ),
  final$1: ({ setScreen }) => (
    <div className="choice">
      <Timer duration={2 * 60 * 1000} onEnded={() => setScreen("final$2")} />
      <p>
        L’enquête arrive bientôt à sa fin… Et oui, déjà ! Nous vous laissons 2
        minutes pour formuler vos hypothèses…
      </p>
    </div>
  ),
  final$2: () => (
    <div className="choice">
      <p>
        Le mystère de la fabuleuse amulette de Miss Frogenchtop sera peut être
        bientôt levé, grâce à vous ! Répondez vite, mais répondez bien !
      </p>
      <QuestionFinal duration={3 * 60 * 1000} questions={[25, 26, 27]} />
    </div>
  ),
  videoWin: ({ setScreen }) => (
    <Video id={13} onEnded={() => setScreen("final$3")} />
  ),
  videoLoose2: ({ setScreen }) => (
    <Video id={14} onEnded={() => setScreen("final$3")} />
  ),
  videoLoose3: ({ setScreen }) => (
    <Video id={15} onEnded={() => setScreen("final$3")} />
  ),
  final$3: () => <GameEnded />,
};

function Game({ team }) {
  const history = useHistory();
  const [started, setStarted] = useState(false);
  const toast = useToast();
  const [
    state,
    { setScreen, initState, setSpeed, clearNotifyDocs, clearLastScore },
  ] = useGame();
  const {
    hash,
    teamPath,
    isSpectator,
    screen,
    score,
    timer,
    lastScore,
    notifyDocs,
    speed,
  } = state;
  const Screen = Screens[screen];

  const notifyDocuments = useCallback(() => {
    if (notifyDocs) {
      toast({
        duration: 4000,
        render: () => (
          <div className="toast">
            <div className="toast-title">
              De nouveaux documents ont été ajoutés !
            </div>
          </div>
        ),
      });

      clearNotifyDocs();
    }
  }, [toast, notifyDocs, clearNotifyDocs]);

  const notifyScore = useCallback(() => {
    if (lastScore.score !== undefined) {
      toast({
        duration: 4000,
        render: () => (
          <div className="toast">
            <div className="toast-title">
              {lastScore.score ? "Bonne réponse !" : "Mauvaise réponse !"}
            </div>
            <div className="toast-content">
              {lastScore.score
                ? `Vous avez marqué ${lastScore.score} points.`
                : "Vous n'avez pas marqué de points."}
            </div>
          </div>
        ),
      });

      clearLastScore();
    }
  }, [toast, lastScore, clearLastScore]);

  useEffect(() => {
    notifyScore();
  }, [notifyScore]);

  useEffect(() => {
    notifyDocuments();
  }, [notifyDocuments]);

  global.debug = window.location.href.includes("DEBUG");
  global.god = window.location.href.includes("GOD");

  return (
    <>
      {started ? (
        <div className={isSpectator ? "game-spectator" : ""}>
          <GameTheme
            team={team}
            score={score}
            screen={
              <div className="screen">
                {Screen ? <Screen setScreen={setScreen} /> : null}
              </div>
            }
            documents={<Documents />}
            timer={timer}
          />
        </div>
      ) : (
        <GameTheme
          screen={
            <div className="screen">
              <div className="choice">
                <p>
                  {isSpectator
                    ? "Prêt ?"
                    : `Attendez que tous les joueurs aient cliqué sur "C'est parti !", puis cliquez "Démarrer" pour lancer la partie.`}
                </p>
                <div className="choice-wrapper">
                  <Button
                    onClick={() => {
                      if (!isSpectator) {
                        setScreen(screen || "intro$1");
                      }
                      setStarted(true);
                    }}
                  >
                    {isSpectator ? "C'est parti !" : "Démarrer"}
                  </Button>
                </div>
              </div>
            </div>
          }
        />
      )}
      {!isSpectator && (
        <>
          <div className="team-code">Code: {hash}</div>
          <div
            className="quit"
            onClick={() => {
              if (
                window.confirm(
                  "Êtes-vous sûr de vouloir quitter ? La partie sera définitivement perdue !"
                )
              ) {
                history.push("/login-master");
              }
            }}
          >
            Quitter
          </div>
          {global.debug && (
            <div className="nav">
              <Select
                size="xs"
                borderColor="transparent"
                onChange={(e) => setScreen(e.target.value)}
                value={screen}
              >
                <option></option>
                {Object.keys(Screens).map((it) => (
                  <option key={it}>{it}</option>
                ))}
              </Select>
            </div>
          )}
          {global.god && (
            <div className="nav">
              <Select
                size="xs"
                borderColor="transparent"
                onChange={(e) => setScreen(e.target.value)}
                value={screen}
              >
                <option></option>
                {Object.keys(Screens).map((it) => (
                  <option key={it}>{it}</option>
                ))}
              </Select>
              <Select
                size="xs"
                borderColor="transparent"
                onChange={(e) =>
                  setSpeed(e.target.value === "vitesse normale" ? 1 : 15)
                }
                value={speed === 1 ? "vitesse normale" : "vitesse rapide"}
              >
                <option>vitesse normale</option>
                <option>vitesse rapide</option>
              </Select>
              <ChakraButton
                size="xs"
                onClick={() => initState({ ...initialState, teamPath })}
              >
                RESET
              </ChakraButton>
            </div>
          )}
        </>
      )}
    </>
  );
}

function LoadMasterGame({ teamPath }) {
  const history = useHistory();
  const [{ name, loaded }, { initState, setScore }] = useGame();

  if (loaded && !name) {
    history.push("/login");
  }

  useEffect(() => {
    db.doc(teamPath)
      .get()
      .then((doc) => {
        const data = doc.data();
        initState({ ...initialState, ...data, teamPath });
      });
  }, [teamPath]);

  useEffect(() => {
    db.doc(teamPath).onSnapshot((doc) => {
      const { scoresById } = doc.data();
      setScore(scoresById);
    });
  }, [teamPath]);

  if (loaded) {
    return <Game team={name.replace(/\s\(.*\)$/, "")} />;
  }

  return null;
}

function LoadSpectatorGame({ teamPath }) {
  const [{ name, loaded }, { setSpectatorState }] = useGame();

  useEffect(() => {
    db.doc(teamPath).onSnapshot((doc) => {
      const data = doc.data();
      setSpectatorState({
        ...initialState,
        ...data,
        teamPath,
        isSpectator: true,
      });
    });
  }, [teamPath]);

  if (loaded) {
    return <Game team={name.replace(/\s\(.*\)$/, "")} />;
  }

  return null;
}

export default function LoadGameProxy() {
  useBeforeunload((event) => {
    if (process.env.NODE_ENV === "production") {
      event.preventDefault();
    }
  });

  const [teamPath, setTeamPath] = useState();
  const params = useParams();

  useEffect(() => {
    db.collection("codes")
      .where("hash", "==", params.hash)
      .get()
      .then(({ docs }) => {
        setTeamPath(docs[0].data().teamPath);
      });
  }, [params.hash]);

  if (!teamPath) {
    return null;
  }

  return params.type === "spectator" ? (
    <LoadSpectatorGame teamPath={teamPath} />
  ) : (
    <LoadMasterGame teamPath={teamPath} />
  );
}
