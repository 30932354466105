import React from "react";
import { useHistory } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { paramCase } from "change-case";
import shortHash from "short-hash";
import muid from "micro-uid";
import Home from "./theme/Home";
import { db } from "./config";

export default function LoginMaster() {
  const history = useHistory();

  return (
    <Home
      form={
        <Formik
          isInitialValid={false}
          initialValues={{
            company: "",
            team: "",
          }}
          validationSchema={Yup.object().shape({
            team: Yup.string().required("Team is required"),
            company: Yup.string().required("Company is required"),
          })}
          onSubmit={async ({ team, company }) => {
            const companyId = paramCase(company);
            const uid = muid();
            const teamId = `${paramCase(team)}--${uid}`;

            const teamPath = `companies/${companyId}/teams/${teamId}`;

            const hash = shortHash(paramCase(teamPath));

            await db.doc(`companies/${companyId}`).set({
              name: company,
            });

            await db.doc(`codes/${hash}`).set({
              hash,
              teamPath,
            });

            await db.doc(teamPath).set({
              hash,
              name: `${team} (${uid})`,
            });

            history.push(`/game/${hash}/master`);
          }}
        >
          {({ isValid, errors, touched, handleSubmit }) => {
            return (
              <Form className="login-form" onSubmit={handleSubmit}>
                <Field
                  name="company"
                  type="text"
                  placeholder="Entreprise"
                  className="field-text"
                  autoComplete="off"
                />
                <Field
                  name="team"
                  type="text"
                  placeholder="Nom d'équipe"
                  className="field-text"
                  autoComplete="off"
                />
                <button
                  type="submit"
                  className="btn"
                  style={{ display: isValid ? "block" : "none" }}
                >
                  Commencer
                </button>
                {touched.code && touched.company && errors.code && (
                  <div className="btn invalid-code">Code non valide !</div>
                )}
              </Form>
            );
          }}
        </Formik>
      }
    />
  );
}
