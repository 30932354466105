import React from "react";
import { useHistory } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import Home from "./theme/Home";

export default function Login() {
  const history = useHistory();

  return (
    <Home
      form={
        <Formik
          isInitialValid={false}
          initialValues={{
            code: "",
          }}
          validationSchema={Yup.object().shape({
            code: Yup.string()
              .min(6, "Code must be at least 6 characters")
              .required("Code is required"),
          })}
          onSubmit={async ({ code }) => {
            history.push(`/game/${code}/spectator`);
          }}
        >
          {({ isValid, errors, touched, handleSubmit }) => {
            return (
              <Form className="login-form" onSubmit={handleSubmit}>
                <Field
                  name="code"
                  type="text"
                  placeholder="Code"
                  className="field-text"
                  autoComplete="off"
                />
                <button
                  type="submit"
                  className="btn"
                  style={{ display: isValid ? "block" : "none" }}
                >
                  Commencer
                </button>
                {touched.code && touched.company && errors.code && (
                  <div className="btn invalid-code">Code non valide !</div>
                )}
              </Form>
            );
          }}
        </Formik>
      }
    />
  );
}
